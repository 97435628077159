// import { Calendar } from "@fullcalendar/core";
import Calendar from "./components/CalendarEvents";
import DataProvider from "./context/DataProvider";
import './index.css';

function App() {
  return (
    <DataProvider>
      <div className="App">
        <Calendar />
      </div>
    </DataProvider>
  );
}

export default App;
