import React, { createContext, useState } from 'react'

export const DataContext = createContext();

const dataDefault = {
    title: "Modal",
    description: "Lorem ipsun"
}

const DataProvider = ({ children }) => {

    const [data, setData] = useState(dataDefault);
    
    return (
        <DataContext.Provider value={{
            data,
            setData
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider