import React, { useState, useContext, useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';


import ModalEvent from './ModalEvent';
import { DataContext } from '../context/DataProvider';

require('globalize/lib/cultures/globalize.culture.es')

const CalendarEvents = (props) => {

    const [show, setShow] = useState(false);
    const [event, setEvent] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const localizer = momentLocalizer(moment)
    const { setData } = useContext(DataContext);

    useEffect(() => {
        fetch('https://camara-arequipa.org.pe/erpccia/resteventos')
            .then((response) => {
                return response.json()
            })
            .then((event) => {
                if(event.data != null)
                    setEvent(event)
            })
    }, [setEvent])

    const lang = {
        es: {
            week: 'Semana',
            work_week: 'Semana de trabajo',
            day: 'Día',
            month: 'Mes',
            previous: 'Atrás',
            next: 'Siguiente',
            today: 'Hoy',
            agenda: 'El Diario',
            showMore: (total) => `+${total} más`,
        }
    }


    const onDoubleClick = (e) => {
        // dispatch(uiOpenModal());
        const data = {
            title: e.title,
            description: e.description,
            start: e.start,
            url: e.url,
            partner: e.partner
        }
        setData(data)
        handleShow()
    }

    const eventStyleGetter = (e) => {
        const type = e.type === '1' ? '#3CBF71' : 'black';
        var style = {
            backgroundColor: type,
            color: 'white',
            fontSize: '.8em'
        };
        return {
            style: style
        };
    }

    return (
        <div className='App'>
            <Calendar
                localizer={localizer}
                events={event.data}
                startAccessor="start"
                endAccessor="end"
                defaultView={'month'}
                views={['month']}
                culture='es'
                messages={lang['es']}
                onSelectEvent={onDoubleClick}
                eventPropGetter={eventStyleGetter}
                popup
            />
            <ModalEvent show={show} handleClose={handleClose} />
        </div>
    )
}

export default CalendarEvents