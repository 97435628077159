import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { DataContext } from '../context/DataProvider';


const ModalEvent = ({ show, handleClose }) => {

  const { data } = useContext(DataContext);

  return (
    <Modal
      show={show}
      onHide={handleClose} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover size="sm">
          <tbody>
            <tr>
              <th>Evento:</th>
              <td>{ data.title }</td>
            </tr>
            <tr>
              <th>Descripción:</th>
              <td>{ data.description }</td>
            </tr>
            <tr>
              <th>Fecha y Hora:</th>
              <td>{ data.start }</td>
            </tr>
            <tr>
              <th>Organizador:</th>
              <td>{ data.partner }</td>
            </tr>
            <tr>
              <th>Más información:</th>
              <td><a target="_blank" rel="noreferrer" href={`${ data.url }`}>{ data.url }</a></td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalEvent